import React, { useEffect, useState } from 'react'

export const CountDown = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date(1655289016000) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return (
        <div className="af-class-section af-class-wf-section">
            <div className="af-class-div-block-4">
                <h1 className="af-class-hackingtime">Hacking Times Square</h1>
                <h1 className="af-class-heading-2">WARNING!<br />HACKING BEGINS IN</h1>
                <div className="af-class-div-block-2">
                    <div className="af-class-div-block">
                        <h1 className="af-class-heading-2">{timeLeft.days}</h1>
                        <h1 className="af-class-heading-4">Days</h1>
                    </div>
                    <h1 className="af-class-heading-5">:</h1>
                    <div className="af-class-div-block">
                        <h1 className="af-class-heading-2">{timeLeft.hours}</h1>
                        <h1 className="af-class-heading-4">Hours</h1>
                    </div>
                    <h1 className="af-class-heading-5">:</h1>
                    <div className="af-class-div-block">
                        <h1 className="af-class-heading-2">{timeLeft.minutes}</h1>
                        <h1 className="af-class-heading-4">MInutes</h1>
                    </div>
                    <h1 className="af-class-heading-5">:</h1>
                    <div className="af-class-div-block">
                        <h1 className="af-class-heading-2">{timeLeft.seconds}</h1>
                        <h1 className="af-class-heading-4">Second</h1>
                    </div>
                </div>
                <div className="af-class-details">
                    <a href="#" className="af-class-button-2 w-button">Details</a>
                    <a href="#" className="af-class-button-2 w-button">Hack</a>
                </div>
            </div>
        </div>
        // <div className="af-class-section af-class-wf-section">
        //     <div className="af-class-div-block-4">
        //         <h1 className="af-class-heading-4">Hacking Times Square</h1>
        //         <h1 className="af-class-heading-2">WARNING!<br />HACKING BEGINS IN</h1>
        //         <div className="af-class-div-block-2">
        //             <div className="af-class-div-block">
        //                 <h1 className="af-class-heading-2">{timeLeft.days}</h1>
        //                 <h1 className="af-class-heading-4">Days</h1>
        //             </div>
        //             <h1 className="af-class-heading-5">:</h1>
        //             <div className="af-class-div-block">
        //                 <h1 className="af-class-heading-2">{timeLeft.hours}</h1>
        //                 <h1 className="af-class-heading-4">Hours</h1>
        //             </div>
        //             <h1 className="af-class-heading-5">:</h1>
        //             <div className="af-class-div-block">
        //                 <h1 className="af-class-heading-2">{timeLeft.minutes}</h1>
        //                 <h1 className="af-class-heading-4">MInutes</h1>
        //             </div>
        //             <h1 className="af-class-heading-5">:</h1>
        //             <div className="af-class-div-block">
        //                 <h1 className="af-class-heading-2">{timeLeft.seconds}</h1>
        //                 <h1 className="af-class-heading-4">Second</h1>
        //             </div>
        //         </div>
        //         <div className="af-class-div-block-3">
        //             <a href="#" className="af-class-button-2 w-button">Details</a>
        //             <a href="#" className="af-class-button-2 w-button">Hack</a>
        //         </div>
        //     </div>
        // </div>
    )
}